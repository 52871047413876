import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import P2PCamCards01 from 'components/Software/iOS/P2P_Cam_Live/p2pCamsCard01';
import P2PCamCards02 from 'components/Software/iOS/P2P_Cam_Live/p2pCamsCard02';
import NavButtons from 'components/Software/iOS/NavButtons';
import ForumBox from 'components/Software/iOS/P2P_Cam_Live/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "iOS Software for your INSTAR IP Camera",
  "path": "/Software/iOS/P2P_Cam_Live/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The iOS app P2P CamLive for your iPad or iPhone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_P2P_Client.png",
  "social": "/images/Search/P_SearchThumb_P2P_Client.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS_P2PCamLive_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='iOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The iOS app P2P CamLive for your iPad or iPhone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_P2P_Client.png' twitter='/images/Search/P_SearchThumb_P2P_Client.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/P2P_Cam_Live/' locationFR='/fr/Software/iOS/P2P_Cam_Live/' crumbLabel="P2P Cam" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "p2p-cam-live",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#p2p-cam-live",
        "aria-label": "p2p cam live permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`P2P Cam Live`}</h3>
    <p>{`To access your IP camera with the iOS App `}<a href="https://itunes.apple.com/us/app/iccam/id883484539?mt=8" target="_blank" rel="noopener noreferrer">{`P2P Cam Live`}</a>{` we recommend that you first download the newest version from the app store. Use the search words "P2PCam Live" to find our app inside the app store.`}</p>
    <h3 {...{
      "id": "search-for-your-p2p-camera-inside-your-local-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#search-for-your-p2p-camera-inside-your-local-network",
        "aria-label": "search for your p2p camera inside your local network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Search for your P2P camera inside your local network`}</h3>
    <p>{`Launch P2P Cam Live on your iPhone or iPad, click on the Add Camera button and search for P2P cameras on your local network. Select the camera you want to add and type in a camera name and your administrator password for the camera´s login. Click on Save in the top right corner to add your camera.`}</p>
    <P2PCamCards01 mdxType="P2PCamCards01" />
    <h3 {...{
      "id": "access-your-cameras-live-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#access-your-cameras-live-stream",
        "aria-label": "access your cameras live stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access your cameras live stream`}</h3>
    <p>{`Click the Camera button to go back to the camera list view. Select your camera to open its live stream. You can use the buttons below to take snapshots, record videos or activate the audio stream.`}</p>
    <P2PCamCards02 mdxType="P2PCamCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      